<template>
  <section class="wrapper">
    <div class="training__block" id="training">
      <h1 class="text-center">Professional Training</h1>
      <p>
        Our Professional Training programs teach you how to tap into nature’s
        wisdom and equip you with the tools to apply biomimicry. Our programs
        and workshops are named for the organisms and natural systems that
        inspire us.
      </p>
      <carousel-3d
        :controls-visible="true"
        :controls-prev-html="'&#10092; '"
        :controls-next-html="'&#10093;'"
        :controls-width="30"
        :controls-height="60"
        :clickable="true"
        class="mt-5"
        :autoplay="true"
      >
        <slide v-for="(item, i) in items" :index="i" :key="i">
          <b-card
            no-body
            :img-src="require(`../assets/images/${item.image}`)"
            :img-alt="item.title"
            img-top
            @click="view(item)"
          >
            <b-card-body>
              <b-card-title>{{ item.theme }}</b-card-title>
              <b-card-sub-title class="text-center">
                {{ item.title }}</b-card-sub-title
              >
            </b-card-body>
          </b-card>
        </slide>
      </carousel-3d>
    </div>
    <training-details
      :visible="showModal"
      :data="data"
      @close="showModal = false"
    ></training-details>
  </section>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
import TrainingDetails from "../components/Modals/TrainingDetails";

export default {
  name: "Training",
  components: {
    Carousel3d,
    Slide,
    TrainingDetails,
  },
  data() {
    return {
      slides: 7,
      showModal: false,
      data: null,
      items: [
        {
          theme: "CICADA",
          image: "deb-dowd-1wx8LNf6HvY-unsplash.jpg",
          title: "Introduction to Biomimicry",
          description:
            "Start your journey in biomimicry with this introductory program. Learn what the practice of biomimicry can offer you. Explore the possibilities of how you can use biomimicry in innovation, research or entrepreneurship.",
          points: [
            "Understand the essential principles of biomimicry",
            "Learn how to apply the biomimicry thinking methodology in your life and work",
            "See how nature’s genius has inspired businesses to innovate and reinvent their thinking",
          ],
          whom:
            "Individuals and teams who want to learn what biomimicry can offer them",
          duration: "3 hours",
        },
        {
          theme: "TERMITE MOUND",
          image: "termite-hill-266587_1920.jpg",
          title: "Solve problems using biomimicry",
          description:
            "Designed to help participants develop the practice of biomimicry, the workshop offers a project-based learning experience. Participants discover a new way of thinking.",
          points: [
            "Learn how to solve problems using the practice of biomimicry",
            "Discover the tools to design new solutions",
            "Design a solution for a selected problem by applying the biomimicry process",
            "Bring sustainable innovation inspired by nature into their work",
          ],
          whom:
            "Researchers, Entrepreneurs and Innovators looking for out-of-the-box solutions",
          duration: "4 weeks",
        },
        {
          theme: "JELLYFISH",
          image: "taylor-boivin-EQtylXVEyNc-unsplash.jpg",
          title: "Biomimicry for innovation",
          description:
            "A custom program for teams that are seeking innovation for a specific challenge or looking for new ideas and fresh thinking. Teams gain a new perspective that can help innovation across their organization.",
          points: [
            "Discover the process to weave nature’s ingenious solutions into problem solving",
            "Discover the tools to design new solutions",
            "A framework for innovating on new products and processes, and re-imagining existing systems",
            "Future-proof the organization ",
          ],
          whom: "Teams looking for innovation tools and processes",
          duration: "Based on requirement",
        },
      ],
    };
  },
  methods: {
    view(item) {
      this.showModal = true;
      this.data = item;
    },
  },
};
</script>

<style scoped>
.wrapper {
  background: url("../assets/images/image--002.jpg") no-repeat;
  background-size: cover;
  height: 550px;
  box-shadow: inset 0 0 0 1000px rgba(69, 124, 151, 0.7);
}

.training__block {
  padding-left: 100px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
}

h1 {
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  /* font-family: "Dosis", sans-serif; */
}

p {
  color: #457c97;
  text-align: justify;
  font-weight: 600;
  font-size: 20px;
}

.carousel-3d-slide {
  cursor: pointer;
}

p {
  color: #fff;
  text-align: center;
}

.card {
  padding: 10px;
}

.card-img-top {
  width: 320px;
  height: 180px;
  object-fit: cover;
  object-position: top;
}

.carousel-3d-slide img {
  /* height: 50%; */
  margin: 0 auto;
}

.card-title {
  color: #195b7e;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.card-text {
  color: #000;
  text-align: center;
  font-size: 14px;
}

>>> span {
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    height: 580px;
  }
  .training__block {
    width: 100%;
    padding: 15px;
  }

  h1 {
    text-align: center;
  }

  h4 {
    font-size: 16px !important;
  }

  .card-img-top {
    height: 160px !important;
    width: 280px;
    object-fit: cover;
    object-position: top;
  }

  /* .carousel-3d-slide img {
    width: 60px;
  } */

  p {
    font-size: 16px !important;
  }
}
</style>