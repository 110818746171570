<template>
  <div class="team__card" @click="view(mentor)">
    <img
      :src="require(`../../assets/images/${mentor.image}`)"
      :alt="mentor.name"
    />
    <h3 class="mt-3 my-2 text-center">{{ mentor.name }}</h3>
    <b>{{ mentor.designation }}</b>
    <Profile
      :visible="showModal"
      :profileData="profileData"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import Profile from "@/components/Modals/Profile";
export default {
  name: "Card",
  components: {
    Profile,
  },
  data() {
    return {
      showModal: false,
      profileData: null,
    };
  },
  props: ["mentor"],
  methods: {
    view(mentor) {
      this.showModal = true;
      this.profileData = mentor;
    },
  },
};
</script>

<style scoped>
img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
.team__card {
  margin-top: 40px;
  cursor: pointer;
  text-align: center;
}
h3 {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize !important;
}

b {
  color: #fff;
}
h5 {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .team__card {
    flex: 100%;
  }
}
</style>