<template>
  <section class="wrapper">
    <div
      class="purpose__block animate__animated animate__fadeInDown"
      id="purpose"
    >
      <h1>Our Vision and Mission</h1>
      <p>
        We envision a world guided and mentored by nature’s genius. Our mission
        is to create a billion biomimics. We catalyse positive change by
        transforming the way humans think and innovate. We enable businesses to
        learn from, translate and adopt nature’s strategies and engineering
        principles in their products, processes and systems. We inspire and
        equip students and professionals to learn from nature and create
        well-adapted, sustainable solutions.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Vision",
};
</script>

<style scoped>
.wrapper {
  background: url("../assets/images/image--001.jpg") no-repeat;
  background-size: cover;
  background-position: right;
  display: flex;
  align-items: center;
  height: 560px;
  box-shadow: inset 0 0 0 1000px rgba(69, 124, 151, 0.7);
}

.purpose__block {
  width: 80%;
  /* padding-left: 100px; */
  /* padding-bottom: 30px; */
  margin: 0 auto;
}

h1 {
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  /* font-family: "Dosis", sans-serif; */
  text-align: center;
}

p {
  color: #fff;
  text-align: justify;
  font-weight: 600;
  line-height: 2;
  font-size: 22px;
  margin-top: 15px;
  text-align: center;
  /* font-weight: bold; */
  /* text-shadow: 2px 2px #457C97; */
}

@media only screen and (max-width: 600px) {
  .wrapper {
    height: auto;
  }
  .purpose__block {
    width: 100%;
    padding: 15px;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 18px;
  }
}
</style>