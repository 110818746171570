<template>
  <section class="wrapper" id="vision">
    <div class="vision__block animate__animated animate__fadeInUp">
      <h1>About Biomimicry Compass</h1>
      <p>
        Biomimicry Compass provides professional training, education and
        consulting to enable innovation using nature’s design strategies and
        engineering principles.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Vision",
};
</script>

<style scoped>
.wrapper {
  box-shadow: inset 0 0 0 1000px rgba(69, 124, 151, 0.7);
  background: url("../assets/images/shaun-low-Yy2cBOqUN_o-unsplash.jpg")
    no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  height: 595px;
  background-position: center;
}

.vision__block {
  width: 80%;
  /* padding-left: 100px; */
  padding-bottom: 30px;
  line-height: 2;
  margin: 0 auto;
}

h1 {
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  /* font-family: "Dosis", sans-serif; */
  text-align: center;
}

p {
  color: #fff;
  text-align: justify;
  font-weight: 600;
  font-size: 24px;
  margin-top: 15px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    height: 580px;
  }
  .vision__block {
    width: 100%;
    padding: 15px;
  }

  h1 {
    text-align: center;
    /* font-size: 26px; */
  }

  p {
    text-align: center;
    font-size: 18px;
  }
}
</style>