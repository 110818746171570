<template>
  <div>
    <b-navbar
      toggleable="lg"
      :class="`${currentRoute === 'People' || isSticky ? 'sticky' : ''}`"
      v-if="currentRoute === 'People' || show"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown right text="Who we are" class="even">
            <b-dropdown-item
              to="/"
              v-scroll-to="{ el: '#about', offset: -100, duration: 3000 }"
              >What is Biomimicry</b-dropdown-item
            >
            <b-dropdown-item
              to="/"
              v-scroll-to="{ el: '#purpose', offset: -250, duration: 3000 }"
              >Our Vision and Mission</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              to="/"
              v-scroll-to="{ el: '#team', offset: -100, duration: 3000 }"
              >People</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right text="What we do" class="odd">
            <b-dropdown-item
              href="#"
              to="/"
              v-scroll-to="{ el: '#training', offset: -130, duration: 3000 }"
              >Professional Training</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              to="/"
              v-scroll-to="{ el: '#services', offset: -140, duration: 3000 }"
              >Consulting and Innovation Services
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            href="https://medium.com/biomimicry-compass"
            target="_blank"
            class="even"
            >Blog</b-nav-item
          >
          <b-nav-item
            href="#"
            to="/"
            v-scroll-to="{ el: '#contact', offset: -100, duration: 3000 }"
            class="odd"
            >Get in touch</b-nav-item
          >
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto not__mbl_logo">
          <b-navbar variant="faded" type="light">
            <b-navbar-brand href="/">
              <img
                :src="require(`../assets/images/${logo}`)"
                alt="Biomimicry"
                width="250"
              />
            </b-navbar-brand>
          </b-navbar>
        </b-navbar-nav>
      </b-collapse>
      <div class="mbl__logo">
        <img src="../assets/images/logo.png" alt="Biomimicry Compass" />
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { logo } from "../_helpers/constants";
import { mapState } from "vuex";

export default {
  name: "NavBar",
  computed: {
    logo() {
      return logo;
    },
    ...mapState({
      currentRoute: (state) => state.currentRoute,
    }),
  },
  data() {
    return {
      isSticky: false,
      show: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY;
      scrollPosition > 60 ? (this.isSticky = true) : (this.isSticky = false);
      scrollPosition >
      document.getElementById("banner").getBoundingClientRect().height
        ? (this.show = true)
        : (this.show = false);
    },
  },
};
</script>

<style scoped>
.nav-item {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-right: 2rem !important;
}

>>> .dropdown-item {
  color: #195b7e !important;
}

>>> .odd > a {
  color: #53a59f !important;
}

>>> .even > a {
  color: #195b7e !important;
}

.navbar-nav {
  padding-left: 80px;
}

.sticky {
  position: fixed;
  top: 0;
  background: #fff;
  margin-top: 0 !important;
  z-index: 999;
  width: 100%;
  -webkit-animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.mbl__logo {
  display: none;
}

@media only screen and (max-width: 600px) {
  .navbar-nav {
    padding-left: 0 !important;
    text-align: center;
  }

  >>> .navbar-nav .dropdown-menu {
    text-align: center !important;
  }

  .not__mbl_logo {
    display: none;
  }

  .mbl__logo {
    display: flex;
    width: 80%;
    height: 50px;
    /* border: 1px solid black; */
    /* justify-content: center; */
    align-items: center;
  }

  .mbl__logo > img {
    width: 100%;
    /* margin-left: -6%; */
  }
}
</style>