<template>
  <section class="wrapper">
    <div class="services__block" id="services">
      <h1 class="text-center">Consulting and Innovation Services</h1>
      <p>
        We work with businesses and industries to create new, sustainable
        products, services and processes. Whether you are seeking innovation or
        looking for transformative solutions or driving sustainability, we can
        provide the tools, processes and resources to help you succeed.
      </p>
      <div class="card__row my-5">
        <!-- <div class="box left-box">
          <div>
            <h5>NEW PRODUCTS, SERVICES, & PROCESSES</h5>
            <span
              >We will help industries build new businesses from
              biomimicry.</span
            >
          </div>
        </div> -->
        <div class="circle">
          <div class="circle-1">
            <div class="circle-2" @click="connect()">Learn More</div>
          </div>
        </div>
        <!-- <div class="box right-box">
          <div>
            <h5>IMPROVING EXISTING BUSINESSES</h5>
            <span
              >Our consulting will also help businesses that need to improve
              their present services to make them meet sustainability
              goals.</span
            >
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      items: [
        {
          image:
            "https://cdn0.iconfinder.com/data/icons/new-product-development-npd/64/NPD-new-product-development-innovation-creative-box-concept-512.png",
          title: "NEW PRODUCTS, SERVICES, & PROCESSES",
          description:
            "We will help industries build new businesses from biomimicry.",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSElK-Q7maec66182FT_glnTB7LqbY-kfl65ssoyqIrz1Y2O4aWUntdgnPhhgm-91gU-sw&usqp=CAU",
          title: "IMPROVING EXISTING BUSINESSES ",
          description:
            "Our consulting will also help businesses that need to improve their present services to make them meet sustainability goals.",
        },
      ],
    };
  },
  methods: {
    connect() {
      this.$store.dispatch("setConnectModal", { isShowing: true });
    },
  },
};
</script>

<style scoped>
.wrapper {
  background: url("../assets/images/shelley-pauls-xLzOLYa1_bs-unsplash.jpg")
    no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(69, 124, 151, 0.7);
  align-items: center;
  height: 570px;
}

.services__block {
  /* padding-left: 100px; */
  padding-top: 50px;
  /* padding-bottom: 30px; */
  line-height: 2;
}

h1 {
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  /* font-family: "Dosis", sans-serif; */
}

p {
  color: #fff;
  text-align: center;
  font-weight: 600;
  width: 80%;
  margin: 0 auto;
  font-size: 22px;
}

.card__row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.card {
  width: 300px;
  padding: 10px;
}

.card-img-top {
  width: 200px;
  margin: 0 auto;
}

.card-title {
  color: #195b7e;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.card-text {
  color: #000;
  text-align: center;
  font-size: 14px;
}

.box {
  width: 30%;
  border: 3px solid #53a59f;
  height: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px;
  text-align: center;
}

h5 {
  font-weight: bold;
}

h5,
span {
  color: #fff;
}

.circle {
  width: 230px;
  height: 230px;
  border: 3px solid #53a59f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-1 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #53a59f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-2 {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-color: #195b7e;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    height: auto;
  }
  .services__block {
    width: 100%;
    padding: 15px;
  }

  h1 {
    text-align: center;
  }

  .card {
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;
  }

  .box {
    width: 100%;
    margin-bottom: 20px;
  }

  .circle {
    margin-bottom: 20px;
  }
}
</style>