<template>
  <b-container>
    <h1 class="text-center">Our Mentors</h1>
    <b-row class="mentor">
      <Card v-for="(mentor, index) in mentors" :key="index" :mentor="mentor" />
    </b-row>
  </b-container>
</template>

<script>
import Card from "../components/People/Card";
export default {
  name: "People",
  components: { Card },
  data() {
    return {
      mentors: [
        {
          image: "image--004.jpg",
          name: "SHIVA SUBRAMANIAM",
          description:
            "Shiva is a life skills coach and facilitator, specialising in creativity, entrepreneurship, and cross-cultural skills. In his career spanning over three decades, he has worked with a multitude of organisations, teams, and individuals, helping them with new ways of thinking, exploring connections, expanding their vision and realizing their potential. <br/> <br /> Shiva is very interested in the unexplored and is constantly seeking new answers. His message to all his students is to be dissatisfied with their solutions to the problems they choose to address – because there is never a final answer to any problem. <br /><br />Shiva is a biomimicry evangelist. He teaches biomimicry to young students with the intention of transforming them to become not just innovators but also sustainability champions. With his penchant to look for connections, he is exploring how coaching can be enhanced through biomimicry.",
        },
        {
          image: "image--005.jpg",
          name: "MRINALINI",
          description:
            "Mrinalini has held many executive positions throughout her career in technology, product management, strategy, innovation and knowledge management, and has headed cross-disciplinary teams locally and globally, dealing with clients in USA, Europe, Asia, South America and the Middle East. She is an alumna of BITS, Pilani and INSEAD.",
        },
        {
          image: "image--006.jpg",
          name: "SIVAKUMAR M SRINIVASAN",
          description:
            "Sivakumar M Srinivasan, fondly called Anbudan Siva is a Professor of Applied Mechanics at IIT Madras specializing in the area of Active Material Structures and Systems. He holds a B.Tech (1985) and an M.S. (1987) both from IIT Madras and a Ph.D. degree (1993) from the Louisiana State University for his thesis in the area of Plasticity. He has been with IIT Madras as a faculty since 1994. <br/> <br />Siva has been a visiting professor at the California Institute of Technology, Pasadena, USA during 2002-2003 and at Texas A&M University for brief periods in 1999 and 2000. <br /><br /> He has brought in several initiatives at IIT Madras. Notable among them was the formation of Mitr as emotional support network for students functioning since 2012. He has also been instrumental in introducing several non-engineering courses at IIT Madras like Life Skills, Creativity, Entrepreneurship, and Happiness which have benefited thousands of students. Being a proponent of hands-on learning, he has run a popular minor program titled Engineering in Everyday Life.",
        },
        {
          image: "image--007.jpg",
          name: "SATYANARAYANAN",
          description:
            "Prof. Satyanarayanan is the faculty adsvisor for Centre for Innovation (CFI) and the Nirmaan pre-incubation center at IIT Madras. His research focus is on indigenous technology development and translation of technology from lab to market in the domains for energy recovery and industrial emission monitoring. He has four granted patents and five patent filings in these domains. He is also the coordinator for the energy committee of the Centre for Technology and Policy at IIT Madras and member of energy committee at the South Indian Chamber of Commerce and Industry (SICCI).",
        },
      ],
    };
  },
};
</script>

<style scoped>
h1 {
  margin-top: 15%;
}
</style>