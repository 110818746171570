import firebase from 'firebase'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDmicqRbKWMfDXbh2PRCxwWUCnES9roncM",
    authDomain: "traineeship-f2fa8.firebaseapp.com",
    projectId: "traineeship-f2fa8",
    storageBucket: "traineeship-f2fa8.appspot.com",
    messagingSenderId: "286625941503",
    appId: "1:286625941503:web:170295bb866c6bf527c9ca",
    measurementId: "G-Y092FS34ZQ"
};
firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const biomimicryQueriesRef = db.collection('biomimicryQueries')


export { biomimicryQueriesRef }