<template>
  <section class="wrapper">
    <div class="about__block" id="about">
      <div class="quote">
        <p>
          "Biomimicry is a practice that learns from and translates the
          strategies found in nature to solve a range of human challenges"
        </p>
        <b-button
          size="lg"
          href="https://medium.com/biomimicry-compass/biomimicry-learning-from-natures-genius-b47fd5b8a70a"
          target="_blank"
          >Know More</b-button
        >
      </div>

      <!-- <div
        class="about__div animate__animated animate__fadeInRight"
        v-for="(item, index) in items"
        :key="index"
        v-show="item.isActive"
      >
        <div class="p-2">
          <h2 class="px-3 mb-4">{{ item.question }}</h2>
          <p v-html="item.answer" class="px-3"></p>
        </div>
      </div>
      <div class="actionBtns">
        <img
          src="../assets/images/previous-arrow.webp"
          alt=""
          @click="previousSlide()"
          v-if="isPreviousSlide"
        />
        <div></div>
        <img
          src="../assets/images/next-arrow.webp"
          alt=""
          @click="nextSlide()"
          v-if="isNextSlide"
        />
      </div> -->
      <!-- <div class="pagination d-flex">
        <b-button
          v-for="(btn, index) in buttons"
          :key="index"
          @click="changeIndex(index)"
          :class="`btn ${btn.isActive ? 'activeBtn' : 'inActiveBtn'}`"
          >{{ index + 1 }}</b-button
        >
      </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      items: [
        {
          question: "What is Biomimicry?",
          answer:
            "You are walking in a forest and you come across a pond. You see some lotus leaves. You are struck by the absence of any dirt on the leaf. You are curious to find out how they are so clean. You study them and you find that the lotus leaves have microscopic bumps on their surface that prevent water from adhering to them. As the water rolls off the surface, it also washes off any particles of dirt. You think about this strategy and apply it to fabric. You invent fabric that, like the lotus leaf, does not accumulate dirt. You save thousands of litres of water that would have otherwise been used for laundering and help mothers of school-going children not labour over washing clothes. You are a biomimic.<br /><br /><b>Biomimicry is a practice that learns from and translates the strategies found in nature to solve a range of human challenges.</b> This is not as implausible a concept as it may first appear. Remember that nature, with 3.8 billion years of evolved wisdom, offers time-tested solutions for all manner of problems. Antibacterial paints that emulate shark skin, air-conditioning buildings by mimicking termite mounds, honeycomb structures applied to aerospace manufacturing and organisational structures modelled on redwood groves - are all examples of biomimicry. ",
          tagline:
            "<b>Why reinvent strategies when we can adapt existing ones to improve the functioning of our companies, the profitability of our businesses, and the overall quality of our lives?</b>",
          isActive: true,
        },
        {
          question: "Why Biomimicry?",
          answer:
            "Due to the many negative effects of human activity on our planet, on the environment and  on ecosystems across the world, earth’s natural life-support systems are rapidly declining and humanity’s survival is under threat. Business as usual with small adjustments will not be enough.<b> A drastic change is needed in all realms of human activity, </b>and the usual short-term solutions will not suffice. <br/> <br /> <b>Biomimicry offers a path towards sustainability.</b> For all the challenges we face, nature has a solution. When it comes to innovation, nature offers 3.8 billion years worth of insights and clever adaptations. If we learn how to imitate nature’s genius, we give ourselves hope and a “new way” to reinvent ourselves. Biomimicry is the “new way”.  <br /><br /> <b>Biomimicry can actually take us beyond sustainable to regenerative, where our designs actually promote life on earth. </b>“The truth is, natural organisms have managed to do everything we want to do without guzzling fossil fuels, polluting the planet, or mortgaging the future.” -  Janine Benyus, co-founder of the Biomimicry Institute ",
          tagline: "",
          isActive: false,
        },
        {
          question: "How can we learn Biomimicry?",
          answer:
            "<b>Biomimicry is innovation inspired by nature.</b> In a society accustomed to dominating or 'improving' nature, this respectful imitation is a radically new approach, a revolution really. Unlike the Industrial Revolution, the Biomimicry Revolution introduces an era based not on what we can extract from nature, but on what we can learn from her. -  Janine Benyus, co-founder of the Biomimicry Institute <br /><br /><b>If you observe any of nature’s designs, strategies and adaptations,</b> from the complex interconnected network of trees in a forest - a ’wood-wide’ web, to the tubercles on whale flippers, from the ant colonies distributing food to the dense bones of birds aiding flight, you’ll notice certain overarching ‘design principles’.<br/> <br />Nature’s building blocks are made from abundantly available resources, using rare resources very sparingly. <b>There are many such lessons that we can derive from organisms, ecosystems and natural models around us,</b> in order to build well-adapted, regenerative solutions. The blueprints and models that have been successful over millenia can help us design out-of-the box solutions faster.",
          tagline: "",
          isActive: false,
        },
      ],
      list: [],
      sliderValue: 0,
      buttons: [
        {
          isActive: true,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
      ],
      isNextSlide: true,
      isPreviousSlide: false,
    };
  },
  methods: {
    changeIndex(index) {
      const activeBtnIndex = this.buttons.findIndex((btn) => btn.isActive);
      const activeSlideIndex = this.items.findIndex((item) => item.isActive);
      this.buttons[activeBtnIndex].isActive = false;
      this.items[activeSlideIndex].isActive = false;
      this.sliderValue = index;
      this.buttons[index].isActive = true;
      this.items[index].isActive = true;
    },
    nextSlide() {
      const nextIndex = this.sliderValue + 1;
      if (nextIndex === this.items.length - 1) {
        this.changeIndex(nextIndex);
        this.isNextSlide = false;
      } else {
        this.changeIndex(nextIndex);
        this.isPreviousSlide = true;
      }
    },
    previousSlide() {
      if (this.sliderValue === 1) {
        this.changeIndex(this.sliderValue - 1);
        this.isPreviousSlide = false;
      } else {
        this.changeIndex(this.sliderValue - 1);
        this.isNextSlide = true;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  background: url("../assets/images/image--000.jpg") no-repeat;
  background-size: cover;
  height: 595px;
}

.about__div {
  height: 450px !important;
  background-color: #fff;
}

.about__block {
  width: 80%;
  /* padding-left: 100px; */
  padding-top: 80px;
  padding-bottom: 30px;
  position: relative;
  z-index: 99;
  margin: 0 auto;
}

.pagination {
  width: 180px;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 10px;
}

.btn {
  font-weight: bold;
  display: block;
  /* justify-content: center; */
  align-items: center;
  background: #195b7e;
  outline: none;
  border: none;
  width: fit-content;
  font-size: 24px;
  margin: 0 auto;
}

.activeBtn {
  background: #195b7e;
  border-color: #195b7e;
  color: #fff;
}

.inActiveBtn {
  background: #fff;
  border-color: #195b7e;
  color: #195b7e;
}

h2 {
  color: #195b7e;
  font-weight: bold;
  /* font-family: "Dosis", sans-serif; */
}

.quote {
  color: #195b7e;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  /* font-family: "Dosis", sans-serif; */
}

.question {
  width: 100%;
  justify-content: space-between;
  outline: none;
}

.actionBtns {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  bottom: 0;
  z-index: 999;
  margin-left: -5%;
}

.actionBtns > img {
  width: 50px;
  cursor: pointer;
  filter: saturate(8);
}

@media only screen and (max-width: 600px) {
  .wrapper {
    /* height: 560px; */
  }
  .about__block {
    width: 100%;
    padding: 15px;
  }

  p {
    font-size: 30px;
    margin-top: 30px !important;
  }

  h2 {
    text-align: center;
  }

  .about__div {
    overflow-y: scroll;
  }

  button {
    margin: 0 auto;
  }
}
</style>