<template>
  <b-container>
    <b-modal size="lg" v-model="show" class="animate__animated animate__zoomIn">
      <div slot="modal-title">
        <img
          src="../../assets/images/logo-biomimicry-compass.png"
          width="200"
        />
      </div>
      <b-row>
        <b-col cols="12" md="6">
          <img
            :src="require(`../../assets/images/${data.image}`)"
            class="image"
          />
          <b-badge variant="primary" class="d-block mx-auto mt-4">{{
            data.theme
          }}</b-badge>
        </b-col>
        <b-col cols="12" md="6">
          <h3>{{ data.title }}</h3>
          <p class="mt-3 ml-4" v-html="data.description"></p>
          <ul v-for="(item, index) in data.points" :key="index">
            <li>
              {{ item }}
            </li>
          </ul>
          <p class="ml-4">
            <b>Who is it for: {{ data.whom }}.</b>
          </p>
          <span class="ml-4">
            <b>Duration: {{ data.duration }}.</b>
          </span>
          <b-button
            class="d-block mt-3 mx-auto"
            style="background: #0e76a8; border: #0e76a8"
            @click="connect()"
            >Learn More</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  name: "Profile",
  props: ["visible", "data"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    connect() {
      this.$emit("close");
      this.$store.dispatch("setConnectModal", { isShowing: true });
    },
  },
};
</script>

<style scoped>
>>> .modal-footer {
  display: none !important;
}

.image {
  width: 500px;
  height: 380px;
  margin-left: auto;
  display: block;
  margin-right: auto;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
  margin-top: 5%;
}

.col {
  display: grid;
  place-items: center;
}

>>> .modal-title {
  /* margin: 0 auto;
  display: block;
  margin-left: 280px;
  text-align: center; */

  margin: 0 auto;
  transform: translate(80%);
  display: block;
}

h3 {
  color: #0e76a8;
  text-align: center;
  margin: 10px 0;
  text-transform: capitalize;
}

p {
  text-align: left;
}

.badge {
  width: fit-content;
  background: #0e76a8;
  font-size: 20px;
}

>>> .modal-dialog {
  max-width: 1180px !important;
  transform: scale(1.5);
}

>>> .modal-title > img {
  width: 100px;
}

@media only screen and (max-width: 600px) {
  >>> .modal-title {
    transform: translate(15%);
    display: block;
    width: 100%;
  }

  .image {
    width: 300px;
    height: 200px;
  }
  .badge {
    font-size: 16px;
  }
}
</style>