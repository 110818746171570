<template>
  <div class="wrapper">
    <div class="banner" id="banner">
      <img
        :src="require(`../assets/images/logo-biomimicry-compass.png`)"
        alt="Biomimicry"
        class="animate__animated animate__zoomIn brand"
      />
    </div>
    <div
      class="downArrow bounce"
      v-scroll-to="{ el: '#vision', offset: -100, duration: 3000 }"
    >
      <img alt="" src="../assets/images/bouncing-arrow.svg" />
    </div>
    <h1 class="mt-5 ml-5 animate__animated animate__zoomIn tagline">
      Sharks, geckos, fungi and termites: <br />
      Meet your 3.8 billion year-old teachers...
    </h1>
  </div>
</template>

<script>
import { logo } from "../_helpers/constants";

export default {
  name: "Landing",
  computed: {
    logo() {
      return logo;
    },
  },
};
</script>

<style scoped>
/* @import url("../assets/css/landing.css"); */
@import url("../assets/css/bouncing-arrow.css");
.wrapper {
  height: 100vh;
  background: url("../assets/images/nature-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.5);
}

.banner {
  width: 600px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
}

.banner > img {
  width: 100%;
}

.tagline {
  font-size: 30px;
  bottom: 9%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  /* font-family: "Dosis", sans-serif; */
  color: #195b7e;
  /* letter-spacing: 2px; */
  text-align: center;
  line-height: 1.5;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .banner {
    width: 100%;
  }
  .brand {
    width: 350px !important;
  }

  .tagline {
    font-size: 20px;
    margin-left: 0 !important;
    width: 100%;
  }

  .downArrow {
    width: 100%;
    left: 0 !important;
  }

  .downArrow > img {
    display: block;
    margin: 0 auto;
    width: 50px !important;
  }
}
</style>