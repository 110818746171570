<template>
  <div>
    <landing></landing>
    <vision></vision>
    <about></about>
    <purpose></purpose>
    <team></team>
    <!-- <team-2></team-2> -->
    <training></training>
    <services></services>
    <Footer></Footer>
    <connect></connect>
    <!-- <dots></dots> -->
  </div>
</template>

<script>
import About from "./About.vue";
import Purpose from "./Purpose.vue";
import Training from "./Training.vue";
import Vision from "./Vision.vue";
import Landing from "./Landing";
import Services from "./Services.vue";
import Footer from "./Footer.vue";
import Connect from "../components/Connect.vue";
import Team from "./Team.vue";
// import Team2 from "./Team2";
// import Dots from "../components/FullPageDotController";

export default {
  name: "Home",
  components: {
    Landing,
    About,
    Vision,
    Purpose,
    Training,
    Services,
    Footer,
    Connect,
    Team,
    // Team2,
    // Dots,
  },
};
</script>

<style scoped>
</style>
