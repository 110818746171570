<template>
  <b-container>
    <b-modal size="lg" v-model="show">
      <div slot="modal-title">
        <img
          src="../../assets/images/logo-biomimicry-compass.png"
          width="200"
        />
      </div>
      <img
        :src="require(`../../assets/images/${profileData.image}`)"
        class="photoURL"
      />
      <h3>{{ profileData.name }}</h3>
      <p class="text-center">{{ profileData.designation }}</p>
      <p class="mt-3" v-html="profileData.description"></p>
      <a :href="profileData.linkedIn" target="_blank">
        <b-icon icon="linkedin" font-scale="1.5"></b-icon>
      </a>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  name: "Profile",
  props: ["visible", "profileData"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped>
>>> .modal-footer {
  display: none !important;
}

>>> .modal-title {
  margin: 0 auto;
  display: block;
  margin-left: 280px;
  text-align: center;
}

p,
a {
  font-weight: bold;
  text-align: center;
  display: block;
  color: #457c97;
}

.photoURL {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-left: auto;
  display: block;
  margin-right: auto;
  object-fit: cover;
  object-position: top;
}
h3 {
  color: #0e76a8;
  text-align: center;
  margin: 10px 0;
  text-transform: capitalize;
  font-weight: bold;
}

>>> .strike {
  text-decoration: line-through !important;
}

@media only screen and (max-width: 600px) {
  >>> .modal-title {
    margin: 0 auto;
    margin-left: 45px !important;
  }
}
</style>