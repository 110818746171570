import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentRoute: '',
    connectModal: { isShowing: false },
  },
  mutations: {
    updatecurrentRoute(state, payload) {
      state.currentRoute = payload
    },
    updateConnectModal(state, payload) {
      state.connectModal = payload;
    },
  },
  actions: {
    setcurrentRoute({ commit }, payload) {
      commit('updatecurrentRoute', payload)
    },
    setConnectModal({ commit }, payload) {
      commit('updateConnectModal', payload)
    },
  },
  modules: {
  }
})
