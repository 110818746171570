<template>
  <section class="wrapper">
    <b-row class="row" id="contact">
      <b-col cols="12" md="7">
        <img
          src="../assets/images/logo-biomimicry-compass.png"
          alt=""
          class="d-block mx-auto"
          width="250"
        />
        <p class="desc">
          Biomimicry Compass provides professional training, education and
          consulting to enable innovation using nature’s design strategies and
          engineering principles.
        </p>
      </b-col>
      <b-col cols="12" md="1"></b-col>
      <b-col cols="12" md="4">
        <div class="links">
          <div>
            <h5>Contact Us</h5>
            <div class="link-items">
              <a href="mailto:innovate@biomimicrycompass.com"
                >innovate@biomimicrycompass.com</a
              >
              <div class="social__handlers mt-3">
                <a href="" class="mr-3" target="_blank">
                  <img src="../assets/images/youtube.png" alt="" />
                </a>
                <a
                  href="https://medium.com/biomimicry-compass"
                  class="mr-3"
                  target="_blank"
                  ><img src="../assets/images/medium.png" alt=""
                /></a>
                <a href="" class="mr-3" target="_blank"
                  ><img src="../assets/images/linkedin.png" alt=""
                /></a>
              </div>
            </div>
          </div>
          <!-- <div>
            <h5>Social</h5>
            <div class="social__handlers">
              <a href="" class="mr-3" target="_blank">
                <img src="../assets/images/youtube.png" alt="" />
              </a>
              <a href="" class="mr-3" target="_blank"
                ><img src="../assets/images/medium.png" alt=""
              /></a>
              <a href="" class="mr-3" target="_blank"
                ><img src="../assets/images/linkedin.png" alt=""
              /></a>
            </div>
          </div> -->
        </div>
      </b-col>
    </b-row>
    <div class="divider"></div>
    <div class="copyright">© 2021 Biomimicry Compass | All Rights Reserved</div>
  </section>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 550px;
  background: rgba(69, 124, 151, 0.8);
  padding: 50px 200px 0 200px;
  /* display: flex; */
  align-items: center;
}

.row {
  margin-top: 10%;
}
.desc {
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

a {
  font-weight: bold;
}

h5 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
}
.links {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
  /* display: flex;
  justify-content: space-between; */
}
.link-items > a {
  display: block;
  margin-top: 20px;
  font-size: 14px;
}
a {
  text-decoration: none;
  color: #fff;
}
.divider {
  margin: 50px 0;
  border-bottom: 2px solid #fff;
}
.copyright {
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .wrapper {
    padding: 10px;
    margin-bottom: 0;
    height: auto;
  }
  .links {
    width: 100%;
    flex-wrap: wrap;
  }
  .links > * {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
  }
  .link-items > a {
    margin-top: 10px;
  }
  h5 {
    margin-bottom: 20px;
  }
  .divider {
    margin: 30px 0;
  }
}
</style>