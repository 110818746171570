<template>
  <section class="wrapper">
    <!-- <particles-bg
      type="cobweb"
      :canvas="{ backgroundColor: '#888' }"
      :bg="true"
      style="position: absolute; background-color: transparent !important"
    /> -->
    <div>
      <h1 class="p-5" id="team">Founders</h1>
      <!-- <b-row
        class="team__row mx-auto"
        v-for="(mentor, index) in mentors"
        :key="index"
      >
        <b-col cols="12" md="2">
          <img
            :src="require(`../assets/images/${mentor.image}`)"
            :alt="mentor.name"
            width="150"
            class="photoURL"
          />
        </b-col>
        <b-col cols="12" md="10">
          <b-row>
            <b-col cols="12" md="12">
              <h3>{{ mentor.name }}</h3>
              <b>{{ mentor.designation }}</b>
              <p v-html="mentor.description"></p>
              <a :href="mentor.linkedIn" target="_blank">
                <b-icon icon="linkedin"></b-icon>
                LinkedIn
              </a>
            </b-col>
          </b-row>
        </b-col>
      </b-row> -->
      <b-row class="mentor">
        <b-col
          class="text-center"
          cols="12"
          md="3"
          v-for="(mentor, index) in mentors"
          :key="index"
        >
          <Card :mentor="mentor" />
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
// import { ParticlesBg } from "particles-bg-vue";
import Card from "../components/People/Card";

export default {
  name: "Team",
  components: {
    // ParticlesBg,
    Card,
  },
  data() {
    return {
      mentors: [
        {
          image: "mrinalini-baw.jpg",
          name: "MRINALINI",
          description:
            "Engineer - software and embedded systems | INSEAD | Fluent in tech and exec | Executive leader in product management, innovation and strategy |  Seeking to make the world a bit better using technology | Avid quizzer | Crossword enthusiast",
          designation: "Chief Executive Officer",
          linkedIn: "http://www.linkedin.com/in/mrinaliniq",
        },
        {
          image: "shiva-baw.jpg",
          name: "SHIVA SUBRAMANIAM",
          description:
            "Innovation champion | Creative thinker | Over 30 years of experience driving learning and development in organizations | Coached the Indian national cricket team and the Delhi Daredevils IPL team on creative thinking | Guest faculty at IIT Madras | Firm believer in the power of imagination - has a real star for a friend | Ardent <span class='strike'>football</span> Manchester United fan",
          designation: "Director - Innovation",
          linkedIn: "https://www.linkedin.com/in/shivathepaperclip",
        },
        {
          image: "sivakumar-baw.jpg",
          name: "SIVAKUMAR SRINIVASAN",
          description:
            "Professor at IIT Madras | Specialises in solid mechanics, smart structures and plasticity | Former Dean of Students | Serial creator of non-engineering courses for engineering students | Founder of Mitr, a counselling and support network for students | Loves to teach engineering skills to schoolchildren | Passionate proponent of hands-on learning sans textbooks",
          designation: "Director - R&D",
          linkedIn: "https://www.linkedin.com/in/anbudan-siva/",
        },
        {
          image: "sathya-baw.jpg",
          name: "SATYA SESHADRI",
          description:
            "Professor at IIT Madras | Specialises in fluid mechanics, energy recovery and industrial emissions monitoring | Focus on developing and commercialising indigenous technologies for process heating and HVAC systems | Faculty head of Nirmaan - pre-incubation centre at IIT Madras | Cheerleader for student-led startups",
          designation: "Director - R&D",
          linkedIn: "https://in.linkedin.com/in/satyaseshadri",
        },
      ],
    };
  },
};
</script>

<style scoped>
.wrapper {
  box-shadow: inset 0 0 0 1000px rgba(69, 124, 151, 0.7);
  background: url("../assets/images/adolfo-felix-BXN16VVFEio-unsplash.jpg")
    no-repeat;
  background-size: cover;
  background-position: bottom;
  padding-bottom: 10px;
  height: 600px;
}

.team__row {
  display: flex;
  justify-content: space-around;
  width: 84%;
  margin: 0 auto;
  margin-bottom: 30px;
}

h1 {
  /* font-family: "Dosis", sans-serif; */
  width: 100%;
  margin: 0 auto;
  color: #fff;
  font-weight: bold;
  text-align: center;
  /* margin-left: 8%; */
}

.photoURL {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

h3 {
  color: #fff;
  font-weight: bold;
}

a,
b,
p {
  color: #fff;
  font-weight: bold;
}

>>> .strike {
  text-decoration: line-through !important;
}

.mentor {
  width: 84%;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    height: auto;
  }

  h1 {
    padding: 0 !important;
  }
  .photoURL {
    display: block;
    margin: 0 auto;
    border-radius: 50%;
  }

  p,
  b {
    text-align: center;
  }

  b {
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
  }

  h3 {
    text-align: center;
    margin: 15px 0;
  }

  a {
    display: block;
    width: 100%;
    text-align: center;
  }
}
</style>